// import logo from './logo.svg';
import './App.css';
import {  BrowserRouter,Routes,  Route } from 'react-router-dom';
import LoginPage from "./Components/signup/login"
import Dashboard from "./Components/Dashboard/dashboard"
import Fetchtoken from "./Components/zerodha/requestedtoken"
import Dashboard2 from './Components/Dashboard/pr2'
function App() {
  return (
    <BrowserRouter>
  
    <Routes>
      
      
     <Route path="/" element={<LoginPage />}></Route>
    
     <Route path="/dashboard" element={<Dashboard/>}></Route>
     <Route path="/zerodha" element={<Fetchtoken/>}></Route>
     {/* <Route path="/dashboard2" element={<Dashboard2/>}></Route> */}

     </Routes>
     
    </BrowserRouter>
  )
}

export default App;
